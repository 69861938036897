<template>
    <div>
        <!-- Cost item dialog -->
        <v-dialog
            :retain-focus="false"
            v-model="itemDialog"
            width="400"
            persistent
        >
            <v-card>
                <v-card-title class="pa-4 pb-0">{{ title }}</v-card-title>
                <v-card-subtitle class="pa-4 pb-0">New Item</v-card-subtitle>
                <v-card-text class="pa-4 pt-0">
                    <v-form v-model="validItem" ref="addItemForm">
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="costItem.name"
                                    hide-details="auto"
                                    label="Name *"
                                    :rules="[rules.required]"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="costItem.weight"
                                    hide-details="auto"
                                    label="Weight"
                                    @change="limitWeight"
                                    type="number"
                                    :rules="[rules.optionalNumber]"
                                />
                            </v-col>

                            <v-col cols="6">
                                <v-autocomplete
                                    v-if="!limitUnits"
                                    v-model="costItem.unit"
                                    :items="units"
                                    label="Units *"
                                    required
                                    :rules="[rules.required]"
                                />
                                <v-autocomplete
                                    v-if="limitUnits"
                                    v-model="costItem.unit"
                                    :items="items2"
                                    label="Units *"
                                    required
                                    :rules="[rules.required]"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-text-field
                                    v-model="costItem.cost"
                                    hide-details="auto"
                                    label="Cost *"
                                    required
                                    :rules="[rules.number]"
                                />
                            </v-col>
                            <v-col cols="6">
                                <v-autocomplete
                                    v-model="costItem.currency"
                                    :items="itemsCurrency"
                                    required
                                    :rules="[rules.required]"
                                    label="Currency *"
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                    <small>* indicates required field</small>
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-btn color="secondary" text @click="closeItemDialog">
                        Cancel
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        :disabled="!validItem"
                        text
                        @click="saveCostItem"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-expansion-panels flat class="mb-4">
            <v-expansion-panel>
                <v-expansion-panel-header
                    color="grey lighten-3"
                    expand-icon="mdi-plus"
                    class="pa-2"
                    style="min-height: unset;"
                >
                    <template>
                        <v-row
                            class="d-flex align-center justify-start"
                            no-gutters
                        >
                            <v-col cols="6">
                                {{
                                    section.reference
                                        ? section.reference.toUpperCase()
                                        : section.name.toUpperCase()
                                }}
                            </v-col>
                            <v-col class="d-flex" cols="6">
                                <v-text-field
                                    solo
                                    rounded
                                    background-color="white"
                                    flat
                                    dense
                                    key="0"
                                    v-model="search"
                                    append-icon="mdi-magnify"
                                    label="Search"
                                    single-line
                                    hide-details
                                    class="pa-0 ma-0 mr-2"
                                ></v-text-field>
                                <v-icon
                                    small
                                    class="ma-2"
                                    @click="openEditSection"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon
                                    small
                                    class="ma-2"
                                    @click="openDeleteSection"
                                >
                                    mdi-delete
                                </v-icon>
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:actions>
                        <v-btn icon @click.stop="openItemDialog()">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="p-0">
                    <v-card class="elevation-0">
                        <v-data-table
                            :headers="headers"
                            :search="search"
                            class="elevation-0"
                            :items="filterCostItems"
                            :items-per-page="-1"
                            :mobile-breakpoint="0"
                        >
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    @click="openItemDialog(item)"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon small @click="deleteCostItem(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <!-- DELETE ITEM -->
        <v-dialog
            :retain-focus="false"
            v-model="deleteDialog"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5">Delete</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this item?
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="deleteDialog = false">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="deleteConfirm()" text color="error">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            :retain-focus="false"
            v-model="editSection"
            persistent
            max-width="300px"
        >
            <v-card>
                <v-card-title class="text-h5">
                    <v-btn
                        class="mr-3"
                        depressed
                        color="primary"
                        icon
                        rounded
                        @click="closeDialogEdit"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    Edit Section
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form">
                        <v-text-field
                            @change="enableEdit"
                            v-model="sectionName"
                            prepend-icon="mdi-folder-edit"
                            label="Name"
                            :rules="[rules.required]"
                            required
                        >
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="!valid"
                        @click="saveEditSection"
                        color="primary"
                        text
                    >
                        Edit
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            :retain-focus="false"
            v-model="deleteSection"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5">Delete</v-card-title>
                <v-card-text>
                    Are you sure you want to delete this section?
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="deleteSection = false"
                    >
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="confirmDeleteSec" text color="error">
                        Delete
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            :retain-focus="false"
            v-model="errorDeleteSection"
            persistent
            max-width="400px"
        >
            <v-card>
                <v-card-title class="text-h5 error--text">Error</v-card-title>
                <v-card-text>
                    You must delete the items in this section to be able to
                    delete it.
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="secondary"
                        @click="errorDeleteSection = false"
                    >
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
export default {
    name: 'SectionForm',
    props: {
        section: Object,
        title: String,
    },
    data() {
        return {
            itemDialog: false,
            editSection: false,
            errorDeleteSection: false,
            deleteDialog: false,
            deleteSection: false,
            indexItem: null,
            limitUnits: false,
            items2: ['kg', 'lb'],
            sectionName: null,
            costItem: {},
            valid: false,
            search: '',
            headers: [
                {
                    text: 'Name',
                    align: 'start',
                    value: 'name',
                },
                { text: 'Weight', value: 'weight' },
                { text: 'Unit', value: 'unit' },
                { text: 'Cost', value: 'cost' },
                { text: 'Currency', value: 'currency' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            units: [],
            validItem: false,
            editingCostItem: null,
            itemsCurrency: [],
            rules: {
                optionalNumber: v =>
                    isNaN(v) || v > 0 || v === '' || 'Invalid number',
                required: v => !!v || 'Required',
                number: v =>
                    (!isNaN(v) && parseInt(v) >= 0) || 'Invalid number',
            },
            editItem: false,
        }
    },
    computed: {
        ...mapState(['settings']),
        // Alphabetical order of items
        filterCostItems() {
            this.sortItems()
            return this.section.costItems
        },
    },
    methods: {
        sortItems() {
            this.section.costItems.sort((a, b) => {
                if (a.name < b.name) {
                    return -1
                }
                if (a.name > b.name) {
                    return 1
                }
                return 0
            })
        },
        openItemDialog(item) {
            if (item) {
                item.index = this.section.costItems.findIndex(
                    element => element.name === item.name
                )
                this.costItem = _.cloneDeep(item)
                this.editItem = true
            }
            this.itemDialog = true
        },
        limitWeight(event) {
            if (event > 0) {
                this.limitUnits = true
            } else {
                this.limitUnits = false
            }
        },
        enableEdit() {
            this.valid = true
        },
        openEditSection() {
            this.editSection = true
        },
        openDeleteSection() {
            if (this.section.costItems.length > 0) {
                this.errorDeleteSection = true
            } else {
                this.deleteSection = true
            }
        },
        closeDialogEdit() {
            this.editSection = false
        },
        confirmDeleteSec() {
            this.$emit('delete', this.section)
            this.deleteSection = false
        },
        saveEditSection() {
            this.section.reference = this.sectionName
            this.$emit('update')
            this.editSection = false
        },
        closeItemDialog() {
            this.costItem = {}
            this.$refs.addItemForm.reset()
            this.itemDialog = false
        },
        saveCostItem() {
            const { index, ...costToSave } = this.costItem
            const newItem = _.cloneDeep(costToSave)
            if (this.editItem) {
                this.section.costItems.splice(index, 1, newItem)
                this.editItem = false
            } else {
                this.editingCostItem = this.section.costItems.findIndex(
                    element => element.name == newItem.name
                )
                if (this.editingCostItem >= 0) {
                    this.section.costItems.splice(
                        this.editingCostItem,
                        1,
                        newItem
                    )
                } else {
                    this.section.costItems.push(newItem)
                }
            }
            this.closeItemDialog()
            this.$emit('update')
        },
        deleteCostItem(item) {
            this.deleteDialog = true
            this.indexItem = this.section.costItems.indexOf(item)
        },
        deleteConfirm() {
            this.section.costItems.splice(this.indexItem, 1)
            this.$emit('update')
            this.deleteDialog = false
        },
        setSettings() {
            const indexExchanges = this.settings.findIndex(
                u => u.name === 'Exchange'
            )
            this.settingExchange = this.settings[indexExchanges]
            this.exchanges = this.settingExchange.exchanges
            this.globalCurrency = this.settingExchange.globalCurrency
            this.exchanges.forEach(v => this.itemsCurrency.push(v.currency))
            this.itemsCurrency.push(this.globalCurrency)
        },
    },
    async created() {
        if (!this.section.costItems) {
            this.section.costItems = []
        }
        this.sectionName = this.section.reference
            ? this.section.reference
            : this.section.name
        this.setSettings()
        var convert = require('convert-units')
        const kind1 = convert().list('mass')
        const kind4 = convert().list('volume')
        const kind2 = convert().list('time')
        const kind3 = convert().list('lenght')
        const kind5 = convert().list('area')
        kind1.forEach(element => {
            const data = element.abbr
            this.units.push(data)
        })
        kind2.forEach(element => {
            const data = element.abbr
            this.units.push(data)
        })
        kind3.forEach(element => {
            const data = element.abbr
            this.units.push(data)
        })
        kind4.forEach(element => {
            const data = element.abbr
            this.units.push(data)
        })
        kind5.forEach(element => {
            const data = element.abbr
            this.units.push(data)
        })
    },
}
</script>

<style scoped>
.no-border {
    border: none !important;
}
tr:not(:last-child) > td.border-grey {
    border-bottom: thin solid #eeeeee !important;
}
tr:hover {
    background: unset !important;
}
th,
td {
    font-size: 0.875rem !important;
}
.v-data-table >>> table {
    border: thin solid #eeeeee;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
    padding: 0;
}
</style>
