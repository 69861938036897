<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-card :loading="loading" flat>
            <v-card-title class="d-flex justify-end pa-0 ">
                <v-btn
                    v-if="setting"
                    @click="openNewSection = true"
                    depressed
                    rounded
                    class="primary mb-2 mt-1"
                >
                    <v-icon left>mdi-plus</v-icon>
                    New Section
                </v-btn>
            </v-card-title>
            <v-dialog
                :retain-focus="false"
                v-if="setting"
                v-model="openNewSection"
                persistent
                max-width="300px"
            >
                <v-card>
                    <v-card-title class="text-h5">
                        <v-btn
                            class="mr-3"
                            depressed
                            color="primary"
                            icon
                            rounded
                            @click="closeDialog"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        Add Section
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-card-text>
                        <v-form v-model="valid" ref="form">
                            <v-text-field
                                @change="addName"
                                prepend-icon="mdi-folder-edit"
                                label="Name"
                                :rules="[rules.required]"
                                required
                            >
                            </v-text-field>
                            <v-textarea
                                label="Description"
                                prepend-icon="mdi-text"
                                @change="addDescription"
                                rows="2"
                            >
                            </v-textarea>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            @click="addSection"
                            :disabled="!valid"
                            color="primary"
                            text
                        >
                            Add
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <div v-if="setting">
                <SectionForm
                    v-for="sec in setting.costSections"
                    :key="sec.name"
                    :title="sec.reference ? sec.reference : sec.name"
                    :section="sec"
                    @update="updateQuoteSettings"
                    @delete="deleteSection"
                />
            </div>
        </v-card>
    </div>
</template>
<script>
import SectionForm from '@/components/Settings/SectionForm'
import API from '@/services/api'
import { v4 as uuidv4 } from 'uuid'

export default {
    name: 'SettingsCosts',
    props: {
        setting: Object,
        pdfImages: Object,
        commercialTerms: Array,
    },
    components: {
        SectionForm,
    },
    data() {
        return {
            height: 0,
            loading: false,
            section: {},
            openNewSection: false,
            valid: false,
            rules: {
                required: v => !!v || 'Required',
                number: v => (v <= 100 && v >= 0) || 'Required',
            },
            error: null,
            errorMsg: null,
            headers: [
                { text: 'NAME', value: 'name', sortable: false },
                { text: 'WEIGHT', value: 'weight' },
                { text: 'UNIT', value: 'unit' },
                { text: 'COST', value: 'cost' },
                { text: 'CURRENCY', value: 'currency' },
            ],
        }
    },
    watch: {
        setting: function() {
            this.setCostsSections()
        },
    },
    methods: {
        onResize() {
            this.height = window.innerHeight - this.$vuetify.application.top
        },
        addSection() {
            this.section.name = uuidv4().substring(0, 8)
            this.setting.costSections.push(this.section)
            this.openNewSection = false
            this.$refs.form.reset()
            this.section = {}
            this.updateQuoteSettings()
        },
        addName(event) {
            this.section.reference = event
        },
        deleteSection(section) {
            const index = this.setting.costSections.findIndex(
                x => x.name == section.name
            )
            this.setting.costSections.splice(index, 1)
            this.updateQuoteSettings()
        },
        updateQuoteSettings: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.setting
                await API.updateSetting(id, this.setting)
                this.quoteChangesAction(true)
            } catch (error) {
                this.error = true
                this.errorMsg = error.message
            } finally {
                this.loading = false
            }
        },
        addDescription(event) {
            this.section.description = event
        },
        closeDialog() {
            this.openNewSection = false
            this.$refs.form.reset()
            this.section = {}
        },
        setCostsSections() {
            if (!this.setting.costSections) {
                this.setting.costSections = []
            }
        },
    },
    created() {
        if (this.setting) {
            this.setCostsSections()
        }
    },
}
</script>

<style scoped>
.border-right {
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: #f0f0f0;
}
.border-bottom {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: #f0f0f0;
}
</style>
